import React, { useState, useEffect } from 'react';

const Pagination = ({ currentPage, totalPages, handleChangePage }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Détecte la taille de l'écran pour adapter la pagination
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Considéré mobile si la largeur de l'écran <= 768px
    };

    handleResize(); // Initialisation à la première render
    window.addEventListener('resize', handleResize); // Mise à jour lors du redimensionnement

    return () => window.removeEventListener('resize', handleResize); // Clean-up
  }, []);

  // Fonction pour faire défiler la page vers le haut
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Fait défiler la page vers le haut
  };

  // Fonction pour générer les boutons de pagination
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxVisibleButtons = isMobile ? 3 : 5; // 3 boutons pour mobile, 5 pour desktop

    // Si le nombre de pages est inférieur ou égal à maxVisibleButtons, afficher toutes les pages
    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            className={`pagination-button ${currentPage === i ? 'current-page' : ''}`}
            onClick={() => { handleChangePage(i); scrollToTop(); }} // Ajout du scroll vers le haut
          >
            {i}
          </button>
        );
      }
    } else {
      // Ajouter la page 1
      buttons.push(
        <button
          key={1}
          className={`pagination-button ${currentPage === 1 ? 'current-page' : ''}`}
          onClick={() => { handleChangePage(1); scrollToTop(); }} // Ajout du scroll vers le haut
        >
          1
        </button>
      );

      // Ajouter des ellipses après la première page si nécessaire
      if (currentPage > 3) {
        buttons.push(<span key="ellipsis1" className="pagination-ellipsis">...</span>);
      }

      // Afficher les pages autour de la page actuelle
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        buttons.push(
          <button
            key={i}
            className={`pagination-button ${currentPage === i ? 'current-page' : ''}`}
            onClick={() => { handleChangePage(i); scrollToTop(); }} // Ajout du scroll vers le haut
          >
            {i}
          </button>
        );
      }

      // Ajouter des ellipses avant la dernière page si nécessaire
      if (currentPage < totalPages - 2) {
        buttons.push(<span key="ellipsis2" className="pagination-ellipsis">...</span>);
      }

      // Ajouter la dernière page
      buttons.push(
        <button
          key={totalPages}
          className={`pagination-button ${currentPage === totalPages ? 'current-page' : ''}`}
          onClick={() => { handleChangePage(totalPages); scrollToTop(); }} // Ajout du scroll vers le haut
        >
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="pagination">
      {/* Bouton First */}
      <button
        className="pagination-button"
        onClick={() => { handleChangePage(1); scrollToTop(); }} // Ajout du scroll vers le haut
        disabled={currentPage === 1}
      >
        First
      </button>

      {/* Bouton Prev */}
      <button
        className="pagination-button"
        onClick={() => { handleChangePage(Math.max(currentPage - 1, 1)); scrollToTop(); }} // Ajout du scroll vers le haut
        disabled={currentPage === 1}
      >
        Prev
      </button>

      {/* Affichage dynamique des pages */}
      {renderPaginationButtons()}

      {/* Bouton Next */}
      <button
        className="pagination-button"
        onClick={() => { handleChangePage(Math.min(currentPage + 1, totalPages)); scrollToTop(); }} // Ajout du scroll vers le haut
        disabled={currentPage === totalPages}
      >
        Next
      </button>

      {/* Bouton Last */}
      <button
        className="pagination-button"
        onClick={() => { handleChangePage(totalPages); scrollToTop(); }} // Ajout du scroll vers le haut
        disabled={currentPage === totalPages}
      >
        Last
      </button>
    </div>
  );
};

export default Pagination;
