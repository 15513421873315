import React, { useState } from 'react';

// Composant de la modale
const Modal = ({ closeModal }) => {
  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        {/* Suppression de la croix */}
        <div className="modal-header">
          <h2>Important Disclaimer</h2>
        </div>
        <div className="modal-body">
          <p>
            The music available on musixfree.com is intended solely for personal, non-commercial use. Any commercial use, including but not limited to reproduction, distribution, or exploitation of the tracks in commercial projects, is strictly prohibited without explicit permission. In this case, the responsibility of musixfree.com is not engaged.
          </p>
        </div>
        <div className="modal-footer">
          <button className="modal-button" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (event) => {
    event.preventDefault(); // Empêche le comportement par défaut du lien
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (

    <div className="footer">
      <div className="footer-content">
        <p>© 2024 - {new Date().getFullYear()} Musix Free. All rights reserved.</p>
        <p className="footer-text main-paragraph">
          Welcome to the world of MusixFree, where rainbows of melodies and harmonious tunes await you! Here at MusixFree, we're all about providing an extensive library of royalty-free music for everyone to enjoy. From the soothing sounds of nature to the electrifying beats of modern pop, our channel has it all!
        </p>
        <div className="footer-button-container">
          <a
            href="https://www.youtube.com/channel/UC2CmXavuIp8-oQv3oK-h4AQ"
            target="_blank"
            rel="noopener noreferrer"
            className="youtube-link"
          >
            <button className="youtube-button">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png"
                alt="YouTube logo"
                className="youtube-logo"
              />
              YouTube Channel
            </button>
          </a>
          <button onClick={openModal} className="terms-button">
            Terms of Use
          </button>
        </div>
      </div>
      {isModalOpen && <Modal closeModal={closeModal} />}
    </div>
  );
};

export default Footer;
