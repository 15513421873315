import React, { useState, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiPlay, mdiPause, mdiDownload } from "@mdi/js";
import Pagination from "./Pagination"; // Import du composant Pagination
import defaultImage from "../images/default-image.png";

const Table = () => {
  const [data, setData] = useState([]); // État pour les données du tableau
  const [searchTerm, setSearchTerm] = useState(""); // État pour le terme de recherche
  const [currentPage, setCurrentPage] = useState(1); // État pour la pagination
  const [playingIndex, setPlayingIndex] = useState(null); // État pour l'index de l'élément en train de jouer
  const [audioPlayer, setAudioPlayer] = useState(null); // État pour l'élément audio
  const [duration, setDuration] = useState("00:00"); // État pour la durée de la musique en cours
  const itemsPerPage = 20; // Nombre d'éléments par page

  // Chargement des données depuis le fichier JSON avec fetch
  useEffect(() => {
    fetch("./data/musicsdata.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Fonction pour gérer le changement de page
  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPlayingIndex(null); // Réinitialiser l'index de lecture lors du changement de page
    if (audioPlayer) {
      audioPlayer.pause(); // Mettre en pause la musique lorsque l'utilisateur change de page
    }
  };

  // Fonction pour gérer le changement du terme de recherche
  const handleSearchTermChange = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); // Réinitialisation de la page à la première page lors du changement de terme de recherche
    setPlayingIndex(null); // Réinitialiser l'index de lecture lors du changement de terme de recherche
  };

  // Fonction pour filtrer les données en fonction du terme de recherche
  const filteredData = data.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.style.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Nombre total de pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Fonction pour gérer le changement de l'état de lecture
  const handlePlayPause = (index, filePath) => {
    if (playingIndex === index) {
      audioPlayer.pause(); // Mettre en pause la musique si elle est en cours de lecture
      setPlayingIndex(null);
    } else {
      audioPlayer.src = filePath; // Charger le fichier audio correspondant
      audioPlayer.play(); // Lancer la musique
      setPlayingIndex(index);
    }
  };

  // Mise à jour de la durée de la musique en cours
  useEffect(() => {
    const updateDuration = () => {
      const currentMinutes = Math.floor(audioPlayer.currentTime / 60);
      const currentSeconds = Math.floor(audioPlayer.currentTime % 60)
        .toString()
        .padStart(2, "0");
      setDuration(`${currentMinutes}:${currentSeconds}`);
    };

    if (audioPlayer) {
      audioPlayer.addEventListener("timeupdate", updateDuration);
    }

    return () => {
      if (audioPlayer) {
        audioPlayer.removeEventListener("timeupdate", updateDuration);
      }
    };
  }, [audioPlayer]);

  // Fonction pour télécharger la musique
  const handleDownload = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.split("/").pop(); // Nom du fichier à télécharger
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="table-container">
      {/* Barre de recherche */}
      <div className="search-container">
        <input
          className="search-input"
          type="text"
          placeholder="Search by title or style..."
          value={searchTerm}
          onChange={(e) => handleSearchTermChange(e.target.value)} // Utilisation de la fonction de gestion du changement de terme de recherche
          onClick={() => {
            if (audioPlayer && !audioPlayer.paused) {
              audioPlayer.pause(); // Mettre en pause la musique lorsque l'utilisateur clique sur la barre de recherche
              setPlayingIndex(null); // Réinitialiser l'index de lecture
            }
          }}
        />
      </div>
      <div className="scrollable-table">
        <div className="table-wrapper">
          {/* Tableau */}
          <table className="music-table">
            <thead>
              <tr>
                <th className="table-header image-column">IMAGE</th>
                <th className="table-header title-column">TITLE</th>
                <th className="table-header style-column">STYLE</th>
                <th className="table-header duration-column">DURATION</th>
                <th className="table-header about-column">ABOUT</th>
                <th className="table-header play-column">PLAY</th>
                <th className="table-header download-column">SAVE</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item, index) => (
                  <tr key={item.title}>
                    <td className="image-column">
                      <img
                        src={item.image || defaultImage}
                        alt={item.title}
                        className="table-image"
                      />
                    </td>
                    <td className="title-column">{item.title}</td>
                    <td className="style-column">{item.style}</td>
                    <td className="duration-column">
                      {playingIndex === index ? duration : item.duration}
                    </td>
                    <td className="about-column">
                      {item.about || "No description"}
                    </td>
                    <td className="play-column centered-button">
                      <button
                        className={`play-button ${playingIndex === index ? "pulse" : ""}`}
                        onClick={() => handlePlayPause(index, item.filePath)}
                      >
                        <Icon
                          path={playingIndex === index ? mdiPause : mdiPlay}
                          size={1.2}
                        />
                      </button>
                    </td>
                    <td className="download-column centered-button">
                      <button
                        className="download-button"
                        onClick={() => handleDownload(item.filePath)}
                      >
                        <Icon path={mdiDownload} size={1.2} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination */}
      <div className="pagination-container">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleChangePage={handleChangePage}
        />
      </div>

      {/* Élément audio pour la lecture de la musique */}
      <audio ref={(audio) => setAudioPlayer(audio)} />
    </div>
  );
};

export default Table;
